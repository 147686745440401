import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as React from 'react';
import * as LY from '_LY_Components';
import nunjucks from "nunjucks";

import { Combobox, ComboboxOptionProps, ComboboxStore } from '@mantine/core';

export class ListColumnManager {
 

  public static getCalculatedValue(col: Api.ListColumn, row: any, value: any) {

    // console.log('ListColumnManager getCalculatedValue value 0:',value);
    // console.log('ListColumnManager getCalculatedValue row:',row);
    //console.log('ListColumnManager getCalculatedValue col:',col);

    try {



      if (!row)
        return value;

      let env = nunjucks.configure({ autoescape: true });
      env.addFilter('currency', function (num) {
         return  num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        // return parseFloat(num).toLocaleString('en-US') //, { style: 'currency', currency: 'USD' }
      });

    //  console.log('ListColumnManager getCalculatedValue value currency: ', value);


      //if(!value)

      //value = row[col.name];

      if (!col)
        return value;

      if (LIB.Common.isNullOrEmpty(col.clientPrepend) &&
        LIB.Common.isNullOrEmpty(col.clientAppend) &&
        LIB.Common.isNullOrEmpty(col.clientCalculation)) {
        return value;
      }

      if (!LIB.Common.isNullOrEmpty(col.clientPrepend))
        value = `${col.clientPrepend}${value}`;

      if (!LIB.Common.isNullOrEmpty(col.clientAppend))
        value = `${value}${col.clientAppend}`;



      if (!LIB.Common.isNullOrEmpty(col.clientPrepend) &&
        !LIB.Common.isNullOrEmpty(col.clientAppend) && value != undefined) {
        value = nunjucks.renderString(value, row);
        row[ col.name ] = value;
      }

     // console.log('ListColumnManager getCalculatedValue value 2: ', value);


      if (!LIB.Common.isNullOrEmpty(col.clientCalculation) && col.clientCalculation != undefined) {
        value = nunjucks.renderString(col.clientCalculation, row);
      }

    //  console.log('ListColumnManager getCalculatedValue value 3: ', value);

      return value;

    }
    catch (ex) {
      console.error('ListColumnManager getCalculatedValue ex:', ex, 'col name:', col.name);

      return value;
    }
  }


  public static isCalculatedColumn(col: Api.ListColumn, onlyCheckTypes: boolean = false) {

    var result = false;
    if (!col)
      return result;

    if (col.listColumnTypeId == App.ListColumnTypeEnum.CalculatedNumber ||
      //col.listColumnTypeId == App.ListColumnTypeEnum.AutoNumber ||
      col.listColumnTypeId == App.ListColumnTypeEnum.CalculatedValue) {
      result = true;
    }
    if (onlyCheckTypes)
      return result;


    if (!LIB.Common.isNullOrEmpty(col.backendCalculation) || !LIB.Common.isNullOrEmpty(col.clientCalculation)) {
      result = true;
    }

    return result;
  }




}