import React, { ChangeEvent, ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';

import './LY_TextArea.css';
import { Button, Popover, Textarea } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';

interface LY_TextAreaProps extends LY.LY_InputBaseProps {
  name: string;
  //type: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  isPopoverStyle?: boolean;
  //  onChange?: (model: LY.DataItemModel | undefined, event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | undefined) => void;

  style?: React.CSSProperties;
  inputProps?: React.InputHTMLAttributes<HTMLTextAreaElement>;
  maxRows?: number;
  onChange?: (model: LY.DataItemModel | undefined, event?: ChangeEvent<any> | undefined) => void;
  onClosedPopup?: (model: LY.DataItemModel | undefined, text?: string) => void;
}

//export const LY_TextAreaBase: React.FC<LY.LY_TextAreaBaseProps> = ({
//export const LY_TextInput = forwardRef<LY_TextInputHandle, LY_TextInputProps>((




type combinedProps = LY_TextAreaProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element - LY_TextArea
export const LY_TextArea = React.forwardRef<HTMLInputElement, LY_TextAreaProps>((
  {
    name,
    // type,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    style,
    onChange,
    model,
    readOnly,
    maxRows = 5,
    inputProps,
    isPopoverStyle,
    onClosedPopup,
    ...inputBaseProps
  },
  ref // This is the forwarded ref
) => {

  const [ innerText, setInnerState ] = React.useState(value || '');
  const focusTrapRef = useFocusTrap();



  function handleClose(){

    if (readOnly)
      return;

    if (model)
      model.value = innerText;

    onChange && onChange(model, innerText)

    onClosedPopup && onClosedPopup(model, innerText)
  }

  function render() {



    var combinedInputClassName = ` LY_TextAreaInputContainer`;

    if (inputBaseProps.error)
      combinedInputClassName += ' LY_TextAreaInput_error'
    else
      combinedInputClassName += ' LY_TextAreaInput_focus'

    if (inputClassName)
      combinedInputClassName += ' ' + inputClassName;


    var readOnlyValue = model?.value;
    if (!readOnlyValue && value)
      readOnlyValue = value;


    return (
      <LY.LY_InputBase  {...inputBaseProps}  >

        {readOnly && (
          <div //title={readOnlyValue}
            className={isPopoverStyle ? 'LY_TextAreaInput_WithResize LY_Scrollbar' : 'LY_TextArea_ReadOnly LY_OverflowDots'}

          >
            {readOnlyValue}
          </div>
        )}

        {!readOnly && (
          <Textarea
            ref={isPopoverStyle ? focusTrapRef : undefined}
            name={name}
            id={inputProps?.id}
            className={combinedInputClassName}
            placeholder={placeholder}
            classNames={{ input: isPopoverStyle ? 'LY_TextAreaInput_WithResize LY_Scrollbar' : 'LY_TextAreaInput LY_Scrollbar' }}

            //  {...inputProps}
            // value={value || ''}
            value={isPopoverStyle ? innerText : value || ''}
            onChange={(e) => {
              if (model)
                model.value = e.target.value;

              //  console.log('LY_TextAreaInput onChange currentTarget.value:',e.currentTarget.value);
              //  console.log('LY_TextAreaInput onChange target.value:',e.target.value);


              /*               if (onChange)
                              onChange(model, e) */

              if (onChange && !isPopoverStyle) {
                onChange(model, e)

              } 
              else if (isPopoverStyle) {
                setInnerState(e.target.value)
              }

            }
            }
            // onChange={(e) => onChange && onChange(model, e)}
            autosize={isPopoverStyle ? true : true}
            maxRows={isPopoverStyle ? undefined : maxRows}
          >
            {/* {value} */}
          </Textarea>
        )}

      </LY.LY_InputBase >
    );

  }

  function renderWithPopover() {

    // console.log('LY_TextArea renderWithPopover columnWidth', model?.column?.columnWidth);

    /*    var readOnlyValue = model?.value;
       if (!readOnlyValue && value)
         readOnlyValue = value; */

    return <Popover
      position="bottom"
      shadow="md"
      withArrow
      withinPortal={true}
      onClose={handleClose}
      zIndex={8000}
      offset={-4}
      classNames={{ dropdown: 'LY_TextEditor_WithPopover_Root' }}
    >

      <Popover.Target>
        <div className="LY_TextEditor_WithPopover_Target"
          style={{ height: '100%', width: '100%', minWidth: model?.column?.columnWidth || 50 }}>{innerText}</div>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="LY_TextEditor_WithPopover_Dropdown">
          {render()}
        </div>
      </Popover.Dropdown>
    </Popover>

  }

  // return  render() ;
  return isPopoverStyle ? renderWithPopover() : render();
});


