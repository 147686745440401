import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { useRef } from 'react';
import { useParams, useLocation } from 'react-router';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Api from 'app-api';
import * as Icons from '@tabler/icons-react';
import { MDXProvider } from '@mdx-js/react'
import MDX from '@mdx-js/runtime';


import './DlaBiddingDetailPage.css';
import { Badge, Tooltip, CloseButton, Modal, Tabs, rem } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { LY_NotesModule } from '_LY_Components/_Modules/LY_NotesModule/LY_NotesModule';
import { LY_StatsGrid } from '_LY_Components/Stats/StatsGrid/LY_StatsGrid';
import { DlaBidStats } from 'Pages/_DLA/Components/DlaBidStats/DlaBidStats';
import { DetailPopupModal } from 'Pages/Detail/DetailPopupModal/DetailPopupModal';

interface DlaBiddingDetailPageProps {
  //state: App.ListState; //this needs to be managed internally
  //model: LY.DataItemModel;
  //onChange?: Function;
  forceReadOnly?: boolean;
  isDLABiddingQuotes?: boolean;
}

export const DlaBiddingDetailPage: React.FC<DlaBiddingDetailPageProps> = (props) => {

  const forceUpdateLib = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();
  const currentUser = App.AppBase.currentUser;


  const workspaceVm = App.AppBase.getCurrentWorkspaceVm();
  const currentWorkspace =workspaceVm.selectedWorkspace;
 

  var initialState = new App.DetailState();

  const [ state, setState ] = React.useState(initialState); // Add this line
  const biddingQuotesTplId = "08e4ce1c-8d8f-4acf-a772-977168e256fb";

  var vm = state.vm;

  var params = useParams();
  var location = useLocation();
  const [ viewId, setViewId ] = React.useState(-1);


  const detailManager: App.LYDetailManager = new App.LYDetailManager(state, forceUpdate, workspaceVm);

  React.useEffect(() => {
    console.log('DlaBiddingDetailPage useEffect');

    var sm = state.sm;

 
    LIB.ObjectHelper.setData(params, sm);
    let workspaceId = Number(params.w);
    if (workspaceId && !isNaN(workspaceId))
      sm.workspaceId = workspaceId;



    getData(sm);

  }, [ params.id ]);


  function forceUpdate(){

    setState({ ...state });
  }

  

  async function getStatsData(itemName:string) {

    console.log('DlaBiddingDetailPage getStatsData', itemName);

    var listId = state.list?.listId!;
    if (!listId || !itemName)
      return;

 
    var dataService: App.DetailDataService = new App.DetailDataService(currentWorkspace.workspaceId!);
 
    dataService.getDetailStats(listId,'',itemName,
      //onSuccess
      (result: App.DetailState) => {
         console.log('DlaBiddingDetailPage getDetailStats onSuccess result:', result);
         vm.apiErrorMessage = '';
         if( result?.statsData){
          state.statsData = result?.statsData;
        
          forceUpdate();
         }
 
        //forceUpdate();

      },
      //onError
      (error: any) => {
        console.error('DlaBiddingDetailPage getDetailStats onError:', error);
        vm.apiErrorMessage = LIB.ErrorHelper.getErrorMessage(error);
        vm.isActionInProgress = false;
        App.AppBase.showError(vm.apiErrorMessage);
        forceUpdate();
      });
 
  }

  async function getData(sm: Api.DetailGetDataSm) {

    console.log('DlaBiddingDetailPage getData sm', sm);

    if (!sm || !sm.rowId || !sm.listId || !sm.workspaceId)
      return;

    sm.includeRelationships = true;
    sm.includeColumns = true;

    var dataService: App.DetailDataService | App.LinkedDetailDataService = new App.DetailDataService(sm.workspaceId);
  
    state.hasDataLoaded = false;

    //listService.getRecords(sm,
    dataService.getDetailsById(sm,
      //onSuccess
      (result: App.DetailState) => {
        // console.log('DlaBiddingDetailPage onSuccess result:', result);

        vm.apiErrorMessage = '';
        LIB.ObjectHelper.setData(result, state, 'vm');
        vm.isActionInProgress = false;
        //var first = result.records[0];
        var data = result.data;
        state.data = data;
        state.workspace = currentWorkspace;
        state.workspaceId = currentWorkspace.workspaceId!;

        var list = state.list;
        if (list?.listId == biddingQuotesTplId || list?.baseTemplateId == biddingQuotesTplId) {
          vm.isDLABiddingQuotesList = true;
        }



        console.log('DlaBiddingDetailPage onSuccess data:', data);
        /*  if (data) {
           fkModel.row = data;
           setFKModel(fkModel);
         }
  */

        state.hasDataLoaded = true;

        forceUpdate();

      },
      //onError
      (error: any) => {

        console.error('DlaBiddingDetailPage onError:', error);

        vm.apiErrorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        App.AppBase.showError(vm.apiErrorMessage);
        forceUpdate();
        //setServerError(errorMessage);
      });


    vm.isActionInProgress = true;

    forceUpdate();

  }


  function getCustomMenuItems(sm:Api.ListGetRelatedDataSm) {


    console.log('getCustomMenuItems sm', sm);

    var items: any[] = [];
    if (LY.LYTemplates.isTemplateListRel(sm, LY.LYTemplates.BiddingLists.supplierQuotesId)) {
      let item = <MT.Menu.Item
        key='menuItemBulkEmailSender'
        leftSection={<Icons.IconMail className='SelectedItemActionsContextMenuIcon' />}
        onClick={() => {
          
          
          state.isBulkEmailSenderOpen = true;
          forceUpdate();

        }}>
        Bulk Email Sender
      </MT.Menu.Item>
      items.push(item);
    }

    return items;

  }


  function getChildLists() {

    var relationshipLists = state.relationshipLists;
    var item = state.data;
    var list = state.list;

    if (relationshipLists == undefined || relationshipLists.length == 0 || !state.hasDataLoaded)
      return null;

    //var workspaceId: number | undefined = state.view?.workspaceId;
    var workspaceId = state.sm.workspaceId;

    var currentWorkspace = App.AppBase.currentWorkspace;
    var linkedWorkspaceId: number | undefined = undefined;


    console.log('getChildLists relationshipLists', relationshipLists);
    var view = state.view;

    /*     if (view.isLinkedView) {
          linkedWorkspaceId = state.view?.workspaceId;
          workspaceId = currentWorkspace?.workspaceId;
        } */

    console.log('getChildLists linkedWorkspaceId', linkedWorkspaceId);
    console.log('getChildLists workspaceId', workspaceId);
    console.log('getChildLists item?.name', item?.name);

    const iconStyle = { width: rem(12), height: rem(12) };


    var tabList: any[] = [];

    relationshipLists.map((item, index) => {
      var tab = <Tabs.Tab
        key={'tab' + item.viewName+index} 
        value={'tab' + item.listId}
        leftSection={<Icons.IconForms style={iconStyle} />}>
        {item.viewName}
      </Tabs.Tab>;
      tabList.push(tab);
    })

    tabList.push(
      <Tabs.Tab key={'tabNotes'} value="notes" leftSection={<Icons.IconMessage style={iconStyle} />}>
        Notes

      </Tabs.Tab>
    );

    if (vm.isDLABiddingQuotesList) {


   /*    tabList.push(
        <Tabs.Tab value="email_emplates" leftSection={<Icons.IconMessageCircle style={iconStyle} />}>
          Email Templates
        </Tabs.Tab>
      );
 */

      tabList.push(
        <Tabs.Tab key={'tabEmails'}  value="emails" leftSection={<Icons.IconMessage style={iconStyle} />}>
          Emails
        </Tabs.Tab>
      );

 

    }

    return <Tabs key={'childLists'}
      defaultValue={'tab' + relationshipLists[ 0 ].listId}
      className='LY_DlaBiddingDetailPageRelationshipTabs'>

      <Tabs.List
        className='LY_DlaBiddingDetailPageRelationshipTabsList'
      >

        {tabList}

      </Tabs.List>




      {
        relationshipLists.map((relInfo, index) => {

          if (!relInfo.viewName)
            return null;

          // console.log('getChildLists relationshipLists filter', list?.filter);

          var relColName = relInfo?.relatedColumn?.name;

          //@ts-ignore
          var relColValue = item[ relInfo?.relatedColumn?.fkListColumnName ];
/*           console.log('getChildLists relationshipLists relColName', relColName);
          console.log('getChildLists relationshipLists relColValue', relColValue);
          console.log('getChildLists relationshipLists item', item); */




          return <Tabs.Panel key={'tabPanel' + relInfo.listId} 
          value={'tab' + relInfo.listId} className='ListViewRelationshipTabPanel'>
            {/*       {item.viewName} */}
            {/*  {item.viewName}  */}
            {/*    {state.sm.listId}   */}

            {/* {workspaceId} */}
            <LY.ListTableView
              {...relInfo}
              forceFilter={true}
              workspaceId={workspaceId}
              linkedWorkspaceId={linkedWorkspaceId}
              hideFooterWhenNoPaging={true}
              //showFilters={true}
              disableNameClick={true}
              presetRelColumnName={relColName}
              presetRelColumnValue={relColValue}
              onSelectedItemsChange={(listState:App.ListState, selectedItems: any[]) => {

                  state.selectedListItems.selectedItemsInfo = relInfo;
                  state.selectedListItems.selectedItems = selectedItems;
                  state.selectedListItems.state = state;
                  state.selectedListItems.listState = listState;
 
                  forceUpdate();

                console.log('DlaBiddingDetailPage onSelectedItemsChange selectedItems', selectedItems);
 

              }}
              customSelectedRecordsCustomMenuItems={getCustomMenuItems(relInfo)}
              onListDataChanged={(listState: App.ListState)=>{
 
               // console.log('DlaBiddingDetailPage onListDataChanged listId', listId);

                if(LY.LYTemplates.isTemplateList(listState.list,LY.LYTemplates.BiddingLists.productDetailsId) ||
                   LY.LYTemplates.isTemplateList(listState.list,LY.LYTemplates.BiddingLists.serviceDetailsId)){

                    console.log('DlaBiddingDetailPage onListDataChanged 2 listState', listState);

                    getStatsData(item?.name);
              }
            }}
     
            />


          </Tabs.Panel>

        })
      }


      <Tabs.Panel value="emails" className='DetailContainerTabPanel DetailPageContainerTabPanel'>
        Coming Soon...
      </Tabs.Panel>

      <Tabs.Panel value="documents" className='DetailContainerTabPanel DetailPageContainerTabPanel'>
        Coming Soon...
      </Tabs.Panel>

      <Tabs.Panel value="notes" className='DetailContainerTabPanel DetailPageContainerTabPanel'>


        <LY_NotesModule
          workspaceId={currentWorkspace.workspaceId}
          currentUser={currentUser}
          recordId={item?.id}
       
        />

      </Tabs.Panel>

    {/*   <Tabs.Panel value="email_emplates" className='DetailContainerTabPanel DetailPageContainerTabPanel'>
        

        <MDXProvider >
          <MDX>{getEmailTemplate()}</MDX>

        </MDXProvider>



      </Tabs.Panel> */}


      <Tabs.Panel value="nsn_history" className='DetailContainerTabPanel DetailPageContainerTabPanel'>
        Coming Soon...
      </Tabs.Panel>


    </Tabs>


  }

  function getBreadcrumbs() {

    var workspaceId = state.view?.workspaceId;
    var propsVm = state.vm;
    let vm = state.vm;
    var item = state.data;
    var list = state.list;


    if (!item?.name)
      return null;

    const items = [
      { title: currentWorkspace?.name, href: '/' },
      { title: list?.name, href: `${list.workspaceId}/list/${workspaceVm?.selectedItem?.defaultViewId}` },
    ];

    if (!App.AppBase.isMobile)
      items.push({ title: item?.name, href: '' });

    var links = items.map((item, index) => {
      if (!item.href)
        return item.title;
      return <MT.Anchor href={item.href} key={index}
        onClick={(e) => {
          e.preventDefault();
          //  console.log('Breadcrumb click', item);
          App.RouteManager.routeToPath(item.href);

        }}
      >
        {item.title}
      </MT.Anchor>
    });

    return <MT.Breadcrumbs style={{ marginTop: 10 }}>
      {links}
    </MT.Breadcrumbs>

  }

  function getEditModeSwitch() {
    var item = state?.data;

    if (!item)
      return null;

    var col = state?.list?.columns?.find(c => c.name == 'bid_status');
    var statusInput = detailManager.getInputSelectorRenderer(col, item);

    return <div className='LY_Flex_Row' style={{gap:10 }}>
      {statusInput}
 
      <LY.LY_Switch
        name='isEditMode'
        label='Edit Mode'
        labelPlacement='left'
        checked={vm.isEditMode}
        onChange={(m, e) => {
          console.log('getEditModeSwitch onChange', m);
          vm.isEditMode = e?.currentTarget?.checked;
          forceUpdate();
        }}
      />
    </div>
  }

  function getOnlyIncludeColumns() {
    return [];
    var colList = '';
    //var list = [ 'name', 'quantity', 'nsn' ];
    var list = colList.split(',');
    return list;
  }

  
  function getSkipColumns() {

    var colList = 'prep_for_delivery,bid_status,bid_price,bid_unit_price';
    //var list = [ 'name', 'quantity', 'nsn' ];
    var list = colList.split(',');
    return list;
  }


  function getBulkEmailSenderModule(){

    console.log('DlaBiddingDetailPage state.isBulkEmailSenderOpen', state.isBulkEmailSenderOpen);
    
    if(!state.isBulkEmailSenderOpen)
      return null;
 
    var reportSm = new Api.RunReportSm();
    reportSm.reportId = LY.LYTemplates.Reports.supplierQuotesBulkEmailReportId;
    reportSm.filter = new Api.ReportQueryFilter();
    reportSm.filter.parameters = {'rfq':state.data?.name};


    return <LY.LY_BulkEmailSenderModule 
    isModal={true}
    workspaceId={currentWorkspace.workspaceId}
    report={reportSm}
    toEmailPropertyNames={['sales_email', 'contact_email']}
    reportFilter={(state:Api.ReportState, selectedViewEmailToItem:any) => {
      console.log('DlaBiddingDetailPage reportFilter state', state);
      if(!state)
        return state;

      var newState = new Api.ReportState();
      newState.records = state.records?.filter(x=>x.id == selectedViewEmailToItem.id);

      console.log('DlaBiddingDetailPage reportFilter records', state.records);
      console.log('DlaBiddingDetailPage reportFilter newState.records', newState.records);

      return newState;
    }}

    isContainerModalOpen={state.isBulkEmailSenderOpen}
    onClose={()=>{

      console.log('DlaBiddingDetailPage onClose isBulkEmailSenderOpen', state.isBulkEmailSenderOpen);

      state.isBulkEmailSenderOpen = false;
      forceUpdate();

      console.log('DlaBiddingDetailPage onClose isBulkEmailSenderOpen2', state.isBulkEmailSenderOpen);

    }}
    emailToItems={state.selectedListItems.selectedItems}
    listState={state.selectedListItems.listState}
    record={state.data}
    itemsNameProperty='_ly_join_suppliers_name_supplier_id'
    itemsIdProperty='id'
    />
  }

  function getRelationshipItemClick(m: LY.DataItemModel | undefined, event: React.MouseEvent<any> | undefined) {

    console.log('DlaBiddingDetailPage getRelationshipItemClick m: ', m);


 
    var col = m?.column;

    console.log('DlaBiddingDetailPage getRelationshipItemClick col: ', col);
    console.log('DlaBiddingDetailPage getRelationshipItemClick column name: ', col?.name);
    vm.selectedDetailModel = m!;
    vm.isDetailPopupModalOpen = true;
    vm.selectedDetailListId = col?.fkListId;
    vm.selectedDetailViewId = col?.fkListViewId;
    forceUpdate();

 
  }


  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var workspaceId = state.view?.workspaceId;
    var propsVm = state.vm;
    let vm = state.vm;

    var item = state.data;
    var title = item?.name || params.name;

    if (state.list?.detailPageTitle)
      title = state.list.detailPageTitle + ' - ' + title;

    var model = new LY.DataItemModel();
    model.row = item;
    model.listId = state.list?.listId;
    model.rowId = item?.id;

    var isMobile = App.AppBase.isMobile;

    // console.log('DlaBiddingDetailPage render vm.isDetailPopupModalOpen', vm.isDetailPopupModalOpen);
    //  console.log('DlaBiddingDetailPage render vm.isRelActionInProgress', vm.isRelActionInProgress);

    /*   if (!item)
        return null;
   */
    //Api.ListGetDataSm 

    // console.log('DlaBiddingDetailPage render state', state);
    // console.log('DlaBiddingDetailPage render fkModel', fkModel);

    var relationshipLists = state.relationshipLists;

    return <App.MainSplitPage
      // pageTitle={<span>{item?.name}</span>}
      state={vm}
      hasDataLoaded={true}//state.hasPageDataLoaded}
      //loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
      // errorMessage={state.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      showLeftMenu={true}
      isFullHeightPage={false}

      mainPanelsContentClassName='LY_DlaBiddingDetailPage_MainSplitPagePanelContent LY_Scrollbar_Panel'
      mainPanelsClassName='LY_Scrollbar_Panel'

    //showRightPanel={vm.isDetailScreenOpen}
    >

      <div className='LY_DlaBiddingDetailPageTopForm'>


        {getBreadcrumbs()}


        <h1 style={{
          display: 'flex',  gap: 20,
          flexDirection: isMobile ? 'column' : 'row'
        }}
        >
          {title} {getEditModeSwitch()}</h1>

        <DlaBidStats 
        contractId={item?.name}
        data={state.statsData}
        />

        <hr style={{ marginBottom: 10, marginTop: 5 }} />
        {/*  <div style={{ display: 'flex', justifyContent: 'end', padding: 10 }}>

          {getEditModeSwitch()}

        </div> */}

        <App.DefaultListTableForm
          state={state}
          model={model}
          //onClose={() => onOpenChange(false)}
          formContainerClassName={'DlaBiddingDetailPageModalFormContainer'}
          formContainerItemClassName={'DlaBiddingDetailPageModalFormContainerItem'}

          excludeFKMultiple={true}
          excludeButtons={!vm.isEditMode}
          readOnly={!vm.isEditMode}
          onClose={() => { vm.isEditMode = false; forceUpdate(); }}

          onlyIncludeColumns={getOnlyIncludeColumns()}
          skipColumns={getSkipColumns()}
          getRelationshipItemClick={getRelationshipItemClick}
        />


      </div>

      {getChildLists()}



      {getBulkEmailSenderModule()}

         <DetailPopupModal
        
              model={vm.selectedDetailModel}
              workspaceId={workspaceId}
             // linkedWorkspaceId={linkedWorkspaceId}
              onClose={() => { vm.isDetailPopupModalOpen = false; forceUpdate(); }}
              isOpen={vm.isDetailPopupModalOpen}

              listId={vm.selectedDetailListId}
              viewId={vm.selectedDetailViewId}

           //   isLinkedView={view.isLinkedView}
          //    forceReadOnly={state.forceReadOnly}

            />
 
    </App.MainSplitPage>


  }



  return render();
};
