import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Api from 'app-api';
import * as Icons from '@tabler/icons-react';


import './DetailPopupModal.css';
import { Badge, Tooltip, CloseButton, Modal, Tabs, rem } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';

interface DetailPopupModalProps {
  //state: App.ListState;  
  isOpen: boolean;
  onClose?: () => void;
  listId?:string;
  viewId?:number;
  linkedWorkspaceId?: number;
  workspaceId?: number;
  model: LY.DataItemModel;
  forceReadOnly?: boolean;
  isLinkedView?: boolean;
  className?: string;
  classNames?: Partial<Record<MT.ModalBaseStylesNames, string>>;
  size?: MT.MantineSize | (string & {}) | number;
}

export const DetailPopupModal: React.FC<DetailPopupModalProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();

 
 
  const [ detailState, setDetailState ] = React.useState(new App.DetailState); 
  const [ fkModel, setFKModel ] = React.useState(new LY.DataItemModel());  
  const [ readOnly, setReadOnly ] = React.useState(false);  
  const [ isOpen, setIsOpen ] = React.useState(false); 

  var vm = detailState.vm;
  const workspaceId = props.workspaceId;

  const workspaceVm = App.AppBase.getCurrentWorkspaceVm();
  const currentWorkspace = workspaceVm?.selectedWorkspace;

  React.useEffect(() => {

    var model = props.model;
//    var propsVm = props.state.vm;

 
    console.log('DetailPopupModal useEffect model', model);
    //console.log('DetailPopupModal useEffect model.value', model?.value);

    if (model == undefined || !props.isOpen)// || !model.column.fkListId)
      return;

  //  if(isOpen)
  //    return;

     setIsOpen(props.isOpen);

     setReadOnly(props.forceReadOnly || false);

 
     setDetailState(new App.DetailState);

     getData(); 

  }, [ props.model, props.isOpen, props.listId, props.viewId ]);


  async function getData() {


    var model = props.model;
 
    console.log('DetailPopupModal getData model 1:', model);
 

    if (model == undefined || model?.column == undefined || model?.value == undefined)
      return;

    //console.log('getData model 2:', model);


/*     if (!model?.column?.fkListId || LIB.Common.isNullOrEmpty(model?.column.fkListColumnName))
      return;
 */

  var listId = props.listId;
  var viewId = props.viewId;

  if (!listId || !viewId)
      return;

    console.log('DetailPopupModal getData model 3:', model);

 
    //var sm = new Api.ListGetDataSm();
    var sm = new Api.DetailGetDataSm();
 

    sm.listId = listId;
    sm.viewId = viewId;

    sm.name = model?.value;
    sm.filter = new Api.QueryFilter();
 

    console.log('DetailPopupModal getData column.name:', model?.column?.name);
    var value = model?.value;
    if(model?.column?.name=="nsn"){

      sm.name = sm.name?.replace(/-/g, '');
      value = sm.name;

      console.log('DetailPopupModal getData NSN:', sm.name);

    }

    var col = model?.column;

    var condition = new Api.FilterCondition();
    condition.name = model.column.fkListColumnName!;
    condition.value = value;
    condition.op = Api.FilterConditionOperatorEnum.Equal
    sm.filter.condition = condition;

    var dataService: App.DetailDataService | App.LinkedDetailDataService = new App.DetailDataService(workspaceId);
    var listService = new App.ListDataService(workspaceId);


    var detailService = new App.DetailDataService(workspaceId);
 

    if (props.isLinkedView) {
      dataService = new App.LinkedDetailDataService(currentWorkspace?.workspaceId);
      dataService.linkedWorkspaceId = workspaceId;
    }
    else if(col?.fkIsLinkedWorkspace){

      var linkedWorkspaceId = col?.fkWorkspaceId;
      dataService = new App.LinkedDetailDataService(workspaceId);
      dataService.linkedWorkspaceId = linkedWorkspaceId;
    }

    if(dataService.linkedWorkspaceId )
     setReadOnly(true);

    console.log('DetailPopupModal condition:', condition);

    /*   console.log('DetailPopupModal sm.filter:', sm.filter);
      console.log('DetailPopupModal state:',props.state);
      console.log('DetailPopupModal view:',props.state.view);
      console.log('DetailPopupModal currentWorkspace:',currentWorkspace);
   */

    detailState.hasDataLoaded = false;

    //listService.getRecords(sm,
    dataService.getDetails(sm,
      //onSuccess
      (result: App.DetailState) => {
        // console.log('DetailPopupModal onSuccess result:', result);

        vm.apiErrorMessage = '';
        LIB.ObjectHelper.setData(result, detailState, 'vm');
        vm.isActionInProgress = false;

        //var first = result.records[0];
        var data = result.data;

        // console.log('DetailPopupModal onSuccess data:', data);
        if (data) {
          fkModel.row = data;
          setFKModel(fkModel);
        }

        detailState.hasDataLoaded = true;
        setDetailState(detailState);

        console.log('DetailPopupModal onSuccess fkModel:', fkModel);

        forceUpdate();

      },
      //onError
      (error: any) => {

        console.error('DetailPopupModal onError:', error);

        vm.apiErrorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        forceUpdate();
        //setServerError(errorMessage);
      });


    vm.isActionInProgress = true;

    //console.log('DetailPopupModal vm.isActionInProgress 2', vm.isActionInProgress);

    forceUpdate();

  }

 

  function onOpenChange(open: boolean) {
 
    setIsOpen(open);
  
    forceUpdate();

    if(!open && props.onClose)
      props.onClose();
  }

  function getChildLists() {
   // var state = props.state;

    var relationshipLists = detailState.relationshipLists;


    if (relationshipLists == undefined || relationshipLists.length == 0)
      return null;


    console.log('getChildLists relationshipLists', relationshipLists);

    console.log('getChildLists workspaceId', workspaceId);
    var wpId = workspaceId;
    var linkedWorkspaceId = props.linkedWorkspaceId;

    if (props.isLinkedView) {
      linkedWorkspaceId =  workspaceId;
      wpId = currentWorkspace?.workspaceId;
    }

    const iconStyle = { width: rem(12), height: rem(12) };


    return <Tabs
     key={'childTabs' + relationshipLists[ 0 ].listId}
      defaultValue={'tab' + relationshipLists[ 0 ].listId} className='ListViewRelationshipTabs'>

      <Tabs.List>

        {
          relationshipLists.map((item, index) => (
            <Tabs.Tab 
            key={'tab' + item.listId+index} 
            value={'tab' + item.listId} leftSection={<Icons.IconForms style={iconStyle} />}>
              {item.viewName} 
            </Tabs.Tab>
          ))
        }

      </Tabs.List>

 


      {
        relationshipLists.map((item, index) => {

          if(!item.viewName)
            return null;

            console.log('getChildLists relationshipLists filter', item.filter);


          return <Tabs.Panel key={'tabPanel' + item.listId} value={'tab' + item.listId} className='ListViewRelationshipTabPanel'>
 
             <LY.ListTableView
             {...item}
              key={'listView' + item.listId+index}
              
              filter={item.filter}
              forceFilter={true}
              workspaceId={wpId}
              linkedWorkspaceId={linkedWorkspaceId || item.linkedWorkspaceId}
              hideFooterWhenNoPaging={true}
            //showFilters={true}
             disableNameClick={true}
             forceReadOnly={true}
             hideCheckboxSelection={true}
           //  tableHeight='100%'
             minTableHeight='100px'
             //autoHeight = {true}
            />  

       

          </Tabs.Panel>

  })
      }

      

    </Tabs>


  }

  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

   let vm = detailState.vm;

    // console.log('DetailPopupModal render vm.isDetailPopupModalOpen', vm.isDetailPopupModalOpen);
      console.log('DetailPopupModal render props.listId', props.listId);

    if (!isOpen)
      return null;

    //Api.ListGetDataSm 

     console.log('DetailPopupModal render fkModel', fkModel);
     

    var relationshipLists = detailState.relationshipLists;

    var modalSize:any = '50vw';
    var modalHeight:any = undefined;
    if(props.listId==LY.LYTemplates.BiddingLists.nsnPartsId){
      modalSize = '70vw';

    }
         


    return (
      <LY.LY_Modal
        id='DetailPopupModalId'
        opened={isOpen}
        onClose={() => onOpenChange(false)}
        title={` ${props.model?.column?.displayName}: ${props.model?.value}`}
        //scrollAreaComponent={ScrollArea.Autosize}
        centered
        overlayProps={{
          backgroundOpacity: 0.1,
          blur: 0,
        }}
        transitionProps={{ duration: 0, timingFunction: 'linear' }}

        size={props.size || modalSize}

        //styles={{body: {height:600, maxHeight:undefined}}}
      
        classNames={{
          ...props.classNames,
          body: `${props.classNames?.body || ''} LY_Scrollbar`.trim(),
        }}
      //className='DetailPopupModal'
          className={'LY_Global_Modals LY_DetailPopupModalContainer ' + (props.className || '')}
      >

        <App.MainContainer

          errorMessage={vm.apiErrorMessage}
          loading={vm.isActionInProgress}
          hasDataLoaded={true}//todo maybe state.hasDataLoaded}
          showContentWhenInProgress={true}
          showModalForLoading={true}
          className='DetailPopupModalContainer'
          
        > 
 
          <App.DefaultListTableForm state={detailState}
            model={fkModel}
            onClose={() => onOpenChange(false)}
            formContainerClassName={'DetailPopupModalFormContainer'}
            excludeFKMultiple={true}
            excludeButtons={true}
            readOnly={readOnly }
          />


          {getChildLists()}  

        </App.MainContainer>

      </LY.LY_Modal>


    );
  }



  return render();
};
