import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class GlobalDataService extends App.BaseAppService  {
 

    constructor(workspaceId: number | undefined=undefined) {

        super();
 
        this.workspaceId = workspaceId;
     }


    public async getInitialData2(workspaceId:number|undefined=undefined, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
 
 
        this.workspaceId = workspaceId;
        const apiUrl = `${App.AppBase.appApiBaseUrl}/GlobalInitialData/getInitialData`;
 
        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("FormViewService.getFormViews onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'GET'
        });
    }


    public async getAppInfoData() {

        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        console.log('getAppInfoData: apiBaseUrl',apiBaseUrl);
        console.log('getAppInfoData: apiBaseUrl2',App.AppBase.appApiBaseUrl);
       
       // var srv = new Api.GlobalDataService();
       // srv.getInitialData().then(x=>)
        const apiUrl = `${App.AppBase.appApiBaseUrl}/GlobalAppData/getAppInfoData`;
 
        await this.get(apiUrl, App.ActionTypes.GLOBAL_APPINFO_DATA_RECEIVED,
            App.ActionTypes.GLOBAL_API_ERROR
            ,"",null,null,false //token not required 
            );
    }
     
    public async getInitialData(workspaceId:number|undefined, onAfterSuccess:Function|null=null) {

        this.workspaceId = workspaceId;
        const apiUrl = `${App.AppBase.appApiBaseUrl}/GlobalInitialData/getInitialData`;
 
        await  this.get(apiUrl, App.ActionTypes.GLOBAL_INITIAL_DATA_RECEIVED,
            App.ActionTypes.GLOBAL_API_ERROR,
            "",null,onAfterSuccess
            );
    }


}