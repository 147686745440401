import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';


import "./LY_DoubleColorInput.css"

import LY_DoubleColorInputPopover from './components/LY_DoubleColorInputPopover/LY_DoubleColorInputPopover';
import React from 'react';
import { TextInput } from '@mantine/core';

interface LY_DoubleColorInputProps {
    item?: {
        name: string;
        text_color: string;
        color: string;
    };
    value?: string;
    index?: number;
    placeholder?: string;
    onInputChange: (value: string) => void;
    error?: string;
    onColorChange: (type: string, value: string) => void;
    onOkClick?: () => void;
    onCancelClick?: () => void;
}

export const LY_DoubleColorInput: React.FC<LY_DoubleColorInputProps> = ({
    item,
    index,
    onInputChange,
    onColorChange,
    error,
    placeholder,
    value,
    ...props
}) => {


    function render(){

        //console.log('LY_DoubleColorInput render item', item);

        return (
            <TextInput
                key={index}
                name={item?.name}
                value={value}
                placeholder={placeholder}
                leftSection={
                    <div className="LY_DoubleColorInput_item_left_section">
                        <LY_DoubleColorInputPopover
                            tooltip="Text Color"
                            initialColor={item?.text_color}
                            onColorChange={(color: string) => onColorChange('text_color', color)}
                            colorType="text"
                            onOkClick={props.onOkClick}
                            onCancelClick={props.onCancelClick}
                        />
                        <LY_DoubleColorInputPopover
                            tooltip="Background Color"
                            initialColor={item?.color}
                            onColorChange={(color: string) => onColorChange('color', color)}
                            colorType="background"
                            onOkClick={props.onOkClick}
                            onCancelClick={props.onCancelClick}
                        />
                    </div>
                }
                onChange={(e) => onInputChange(e.target.value)}
                styles={{
                    wrapper: {
                        minHeight: "30px"
                    },
                    input: {
                        minHeight: "30px",
                        height: "30px",
                    }
                }}
                classNames={{
                    section: 'LY_DoubleColorInput_item_left_section_wrapper',
                    wrapper: 'LY_DoubleColorInput_item_input_wrapper',
                    root: 'LY_DoubleColorInput_item_input_root',
                    input: 'LY_DoubleColorInput_item_input',
                }}
                error={error}
            />
        );
    }


  return render();
};

