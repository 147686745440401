import React, { HTMLAttributes } from 'react';

import { Badge, Tooltip } from '@mantine/core';

import * as LY from '_LY_Components';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as MT from '@mantine/core';

import './LY_DynamicDropDown.css';

interface LY_DynamicDropDownProps extends LY.LY_DropDownProps {

  dataSets?: Api.ListDropDownDataResult[];
  onDataLoaded?: (result: Api.ListDropDownDataResult) => void;

  currentListId: string;
  currentColumnId: string;

  //fk column info
  listId: string;
  displayColumnId: string;
  valueColumnId: string;
  viewId?: number;
  linkedWorkspaceId?: number;

} 

type combinedProps = LY_DynamicDropDownProps & React.InputHTMLAttributes<HTMLInputElement>;
 
// Use React.forwardRef to forward the ref to the select element - LY_TextArea
export const LY_DynamicDropDown = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    listId,
    currentColumnId,
    displayColumnId,
    valueColumnId,
    viewId,
    isAutoCompleteStyle = true,
    onDataLoaded,
    ...props
  },
  ref // This is the forwarded ref
) => {

  const currentWorkspace = App.AppBase.currentWorkspace;
  
  const forceUpdate = LIB.useForceUpdate();
  var initialVm = new LY.DynamicDropDownModelVm();
  const [ vm, setVm ] = React.useState<LY.DynamicDropDownModelVm>(initialVm); 
  const [ selectedItem, setSelectedItem ] = React.useState<any>(); 


  React.useEffect(() => {
/*     console.log('LY_DynamicDropDown useEffect listId: ', listId);
    console.log('LY_DynamicDropDown useEffect valueColumnId: ', valueColumnId);
    console.log('LY_DynamicDropDown useEffect props.selectedItem: ', props.selectedItem);
 */
     // getData()

     if(props.selectedItem)
     setSelectedItem(props.selectedItem);

  }, [ props.selectedItem ]);

 
  function onServiceError(error: any) {

    console.error('LY_DynamicDropDown onServiceError:', error);
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    vm.isActionInProgress = false;
    vm.errors.set('all', errorMessage);
     forceUpdate();

  }

  function getData(searchKeyword:string|undefined=undefined) {
 
    console.log('LY_DynamicDropDown getData searchKeyword:', searchKeyword);

    var currentWorkspaceId = currentWorkspace?.workspaceId;
 
    console.log('LY_DynamicDropDown getData currentWorkspaceId:', currentWorkspaceId);
    console.log('LY_DynamicDropDown getData isLinkedWorkspace:', currentWorkspace.isLinkedWorkspace);
    console.log('LY_DynamicDropDown getData props.linkedWorkspaceId:', props.linkedWorkspaceId);
    //console.log('LY_DynamicDropDown getData sm.linkedWorkspaceId:', sm.linkedWorkspaceId);

    if (!currentWorkspaceId )
      return;

    var service = new App.DropDownService(currentWorkspaceId,props.linkedWorkspaceId);

     var valueProperty = vm.currentResult?.valueColumnName || 'id';

    var sm = new Api.ListGetDropDownDataSm();
    sm.listId = listId;
    sm.displayColumnId = displayColumnId;
    sm.valueColumnId = valueColumnId;
    sm.currentColumnId = currentColumnId;
    sm.currentListId = props.currentListId;

    sm.viewId = viewId;

     sm.filter = new Api.QueryFilter();
     sm.filter.searchKeyword  = searchKeyword


    if(props.selectedItem)
      sm.selectedValue =  props.selectedItem[props.name];

      console.log('LY_DynamicDropDown getData sm:', sm);

    service.getData(sm,
      //onSuccess
      (result: Api.ListDropDownDataResult) => {
        console.log('LY_DynamicDropDown onSuccess:', result);

        if (onDataLoaded)
          onDataLoaded(result);

        vm.isActionInProgress = false;
        vm.currentResult = result;

        if(result.totalRecordsCount && result.recordsCount && !searchKeyword)
           vm.enableServerSideSearch = (result.totalRecordsCount>result.recordsCount);
        
        setVm({...vm});

      },
      //onError
      onServiceError
    );

    vm.isActionInProgress = true;
    forceUpdate();

  }

  function onDropdownOpen(e:MT.ComboboxDropdownEventSource){
    console.log('LY_DynamicDropDown onDropdownOpen e:', e);
    console.log('LY_DynamicDropDown onDropdownOpen vm:', vm);

    getData();



  }

  function getSelctedItem(val:any=undefined){
    var valueProperty = vm.currentResult?.valueColumnName || 'id';
    let records = vm?.currentResult?.records;

    if(!props.selectedItem || !records)
      return null;

    if(val===undefined)
      val = props.selectedItem[props.name];

    let selectedItem = records?.find(x=>x[valueProperty] == val || x[valueProperty] == val[valueProperty] );
    return selectedItem
  }

  
  function onSearchChange(val: string) {
    console.log('LY_DynamicDropDown onSearchChange val:', val);
    getData(val);
  }

  function onLocalValueChange(m: LY.DataItemModel, val:any, inSelectedItem:any, optionProps: MT.ComboboxOptionProps | undefined = undefined, combobox?:MT.ComboboxStore|undefined) {
    console.log('LY_DynamicDropDown onLocalValueChange  val:', val);

    var valueProperty = vm.currentResult?.valueColumnName || 'id';
    let data = vm.currentResult?.records;

   // var sItem = getSelctedItem(val);
    var newSelectedItem:any = undefined;
   if(val){
     newSelectedItem = data?.find(x=>x[valueProperty] == val || x[valueProperty] == val[valueProperty]  );

    if(newSelectedItem){
      val = newSelectedItem[valueProperty];
      if(m)
        m.value = val;
    }
   }
   
    //var newVal = selectedItem?[valueProperty];
   // console.log('LY_DynamicDropDown onLocalValueChange  m:', m);
    console.log('LY_DynamicDropDown onLocalValueChange  val:', val);
    console.log('LY_DynamicDropDown onLocalValueChange  m.value:', m.value);
    //console.log('LY_DynamicDropDown onLocalValueChange  val:', val);

    //console.log('LY_DynamicDropDown onLocalValueChange  data:', vm?.currentResult?.records);

    console.log('LY_DynamicDropDown onLocalValueChange  newSelectedItem:', newSelectedItem);

    setSelectedItem(newSelectedItem);

    if(props.onValueChange)
      props.onValueChange(m,val,selectedItem,optionProps,combobox);

  }
 
  function render(){

    var data = vm.currentResult.records;
    var valueProperty = vm.currentResult?.valueColumnName || 'id';
    var displayProperty = vm.currentResult?.displayColumnName || 'name';
    var selectedItem2:any=getSelctedItem() || props.selectedItem;
    
  /*   console.log('LY_DynamicDropDown render props.selectedItem:', props.selectedItem);
    console.log('LY_DynamicDropDown render selectedItem:', selectedItem);
    console.log('LY_DynamicDropDown render displayProperty:', displayProperty);
    console.log('LY_DynamicDropDown render data:', data); */
   // console.log('LY_DynamicDropDown render label:', props.label);
/* 
    if(props.name=='company' ){//&& props.value=='2f76b77a-c318-4ac9-985c-3370549a8c33'){
      console.log('LY_DynamicDropDown fsc_code  data:', data);
      console.log('LY_DynamicDropDown fsc_code  selectedItem:', selectedItem);

   //   console.log('LY_DynamicDropDown fsc_code  readOnly:', props.readOnly);
    }  */

  return <LY.LY_DropDown
     {...props}
    selectedItem={selectedItem}
    displayProperty={displayProperty}
    valueProperty={valueProperty}
    data={data}
    isAutoCompleteStyle={isAutoCompleteStyle}
    onDropdownOpen={(e)=>onDropdownOpen(e)}
    onSearchChange={vm.enableServerSideSearch?onSearchChange:undefined}
    isLoading={vm.isActionInProgress}
    onValueChange={onLocalValueChange}

  /*   onValueChange={(m: LY.DataItemModel, val: any, inSelectedItem:any) => {
      console.log('LY_DynamicDropDown onValueChange onValueChange:', val);
      console.log('LY_DynamicDropDown onValueChange inSelectedItem:', inSelectedItem);
      console.log('LY_DynamicDropDown onValueChange m:', m);

      onLocalValueChange(m, val, inSelectedItem);
    }} */

  />

}


  return render();
});


